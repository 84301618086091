button{
  border: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}

button:hover{
  opacity: 80%;
  cursor: pointer;
}

.image{
  width: 60%;
  height: auto;
  border: 0.2rem solid white;
  border-radius: 20px;
  padding: 0.9rem;
}

.landpg{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name{
  font-size: 5rem;
  text-align: center;
}

.description{
  font-size: 1.5rem;
  align-items: center;
  text-align: center;
}

.blktxt{
  color: black;
}

.nav-items{
  color: white;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer{
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: fixed;
  bottom: 0;
  right: 0;
  width: fit-content;
  margin-bottom: 1.1rem;
  background-color: none;
}

.contact-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
}

.linked-in{
  margin-right: 1rem;
}


.mynavbar{
  position: fixed;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 999;
}

a#scroll-btn {
  position: absolute;
  height: 5rem;
  width: 2.5rem;
  border: 0.15rem solid #ffffff;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 3.5rem;
  border-radius: 3em;
}

a#scroll-btn:before {
  position: absolute;
  content: "";
  margin: auto;
  left: 0;
  right: 0;
  top: 0.25rem;
  height: 0.9rem;
  width: 0.9rem;
  background-color: #ffffff;
  border-radius: 50%;
  animation: move-down 2.5s infinite;
}

@keyframes move-down {
  80% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(3.2rem);
    opacity: 0;
  }
}

@media screen and (max-width: 500px) {
  a#scroll-btn {
    font-size: 1em;
  }
}

.navbt{
  padding-right: 1.5rem;
}

.projectpg{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.project-list{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30vw;
  height: 80vh;
}

.project{
  display: flex;
  border: 0.2rem solid white;
  width: 30vw;
  height: 15vh;
  border-radius: 67px;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.project-text{
  display: flex;
  flex-direction: column;
  width: 20vw;
  margin: auto;
  align-items: center;
}

.project-title{
  font-family: NeueMontreaMedium;
  font-size: 1.5rem;
  color: black;
}

.project-description{
  font-size: 1.1rem;
  text-align: center;
}

.project:hover{
  border-style: dashed;
}

.project-demonstration{
  width: 60vw;
  height: 80vh;
}

.site{
  padding: 2rem;
  width: 60vw;
  height: 80vh;
  border: 0.2rem solid white;
  border-radius: 67px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.abt-project{
  font-size: 1.5rem;
  text-align: center;
  width: 45vw;
}

.skills-used{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.smskill{
  font-size: 1.2rem;
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  margin: 1rem;
}

.skills-used-text{
  font-size: 1.5rem;
}

.contact-page{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form{
  border: 0.2rem solid white;
  border-radius: 67px;
  height: 75vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.contact-text{
  font-size: 1.2rem;
  margin: 1rem;
  text-align: center;
}

.contact-name{
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-name .name-input{
  border: 0.2rem solid white;
  width: 40vw;
  height: 40px;
  border-radius: 67px;
  margin: 0;
}

.contact-email{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-email .email-input{
  border: 0.2rem solid white;
  width: 40vw;
  height: 40px;
  border-radius: 67px;
  margin: 0;
}

.contact-message{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-message .message-input{
  border: 0.2rem solid white;
  width: 40vw;
  height: 25vh;
  border-radius: 67px;
  margin: 0;
  text-align: left;
  resize: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.message-input:focus, .name-input:focus, .email-input:focus{
  outline: none;
}

.name-input, .email-input{
  padding: 1.5rem;
}

.contact-submit{
  font-size: 1.25rem;
  border: 0.2rem solid white;
  border-radius: 67px;
  width:fit-content;
  padding: 1rem;
  background-color: white;
  color: black;
}

.contact-submit:hover{
  border: 0.2rem solid white;
  color: black;
  background-color: white;
}

.about-page{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.about-me{
  font-size: 3rem;
  width: 60vw;
  text-align: center;
  margin-left: 1rem;
}
.about-skills{
  width: 40vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-title{
  font-size: 3rem;
  width: max-content;
  padding: 1rem;
  text-align: center;
}

.skill-list{
  display: flex;
  flex-direction: row;
  width: 30vw;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.skill{
  font-size: 1.5rem;
  border: 0.2rem solid black;
  border-radius: 67px;
  padding: 0.5rem;
  text-align: center;
  margin: 0.1rem;
}

.about-resume{
  font-size: 1.5rem;
  border: 0.2rem solid white;
  border-radius: 67px;
  width:fit-content;
  padding: 1rem;
  margin-top: 1rem;
  background-color: white;
  color: black;
}

@media screen and (min-width: 300px) and (max-width: 500px){
  .project{
    display: flex;
    border: 0.2rem solid white;
    width: 30vw;
    height: 15vh;
    border-radius: 67px;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  
  .project-text{
    display: flex;
    flex-direction: column;
    width: 20vw;
    margin: auto;
    align-items: center;
  }
  
  .project-title{
    font-family: NeueMontreaMedium;
    font-size: 1rem;
    color: black;
  }
  
  .project-description{
    font-size: 0.65rem;
    text-align: center;
  }

  .abt-project{
    font-size: 1rem;
    text-align: center;
    width: 45vw;
  }

  .project-date{
    font-size: 0.5rem;
  }

  .skills-used-text{
    font-size: 1rem;
  }

  .smskill{
    font-size: 0.85rem;
  }

  image{
    display: none;
  }

  .name{
    font-size: 3rem;
  }

  .description{
    font-size: 0.9rem;
  }

  .image{
    width: 120%;
    height: auto;
  }

  .contact-form{
    border-radius: 30px;
  }

  .project{
    border-radius: 45px;
  }
  
  .about-me{
    font-size: 1.5rem;
    width: 60vw;
    text-align: center;
    margin-left: 1rem;
  }

  .about-resume{
    font-size: 1.5rem;
    border: 0.2rem solid white;
    border-radius: 67px;
    width:fit-content;
    padding: 0.5rem;
    margin-top: 1rem;
    background-color: white;
    color: black;
  }

  .skill-title{
    font-size: 1.5rem;
    width: max-content;
    padding: 1rem;
    text-align: center;
  }
  
  .skill{
    font-size: 1rem;
    border: 0.2rem solid black;
    border-radius: 67px;
    padding: 0.5rem;
    text-align: center;
    margin: 0.1rem;
  }

  .contact-submit{
    font-size: 1rem;
    border: 0.2rem solid white;
    border-radius: 67px;
    width:fit-content;
    padding: 0.75rem;
    background-color: white;
    color: black;
  }

  .contact-form{
    border: 0.2rem solid white;
    border-radius: 67px;
    height: 75vh;
    width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  
}